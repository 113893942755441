import React from "react";
import { I18n } from "react-i18next";
import { graphql } from "gatsby";
import { withI18next } from "gatsby-plugin-i18next";
import { ThemeProvider } from "emotion-theming";
import { Inicio } from "../views/Inicio";
import { Footer } from "../views/Footer";
import { Cognitiva } from "../views/Cognitiva";
import { Solutions } from "../views/Solutions";
import { theme } from "../theme";
import { Section } from "../components/Section";
import { Contact } from "../views/Contact";
import { SectionsProvider } from "../context/SectionsContext";
import { GlobalStyles } from "../components/GlobalStyles";
import SEO from "../components/SEO";
import Header from "../views/Header";
import AboutUs from "../views/AboutUs";
import OurNumbers from "../views/OurNumbers";
import Team from "../views/Team";
import OurAllies from "../views/OurAllies";

if (
  typeof window !== "undefined" &&
  window &&
  window.location.protocol !== "https:"
) {
  window.location =
    "https:" + window.location.href.substring(window.location.protocol.length);
}

const Index = () => (
  <I18n>
    {translate => (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <SEO />
        <SectionsProvider translate={translate}>
          <Header />
          <Section id="section-start" className="flex inicio">
            <Inicio translate={translate} />
          </Section>
          <Section id="section-create" className="inicio-creamos">
            <Cognitiva translate={translate} />
          </Section>
          <Section id="section-aboutus" className="nosotros">
            <AboutUs translate={translate} />
          </Section>
          <Section id="section-solutions" className="soluciones">
            <Solutions translate={translate} />
          </Section>
          <Section id="section-ournumbers" className="soluciones-numeros">
            <OurNumbers translate={translate} />
          </Section>
          <Section id="section-team" className="equipo">
            <Team translate={translate} />
          </Section>
          <Section id="section-allies" className="equipo-aliados">
            <OurAllies translate={translate} />
          </Section>
          <Section id="section-contact" className="contactanos">
            <Contact translate={translate} />
          </Section>
          <Footer translate={translate} />
        </SectionsProvider>
      </ThemeProvider>
    )}
  </I18n>
);

export default withI18next()(Index);
export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { ns: { eq: $lng } }) {
      ...TranslationFragment
    }
  }
`;
